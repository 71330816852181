<template>
  <main class="page-main-bg" id="requestPage">
    <!-- Start container -->
    <v-container class="main-container position-relative">
      <!---------------Toolbar------------------>
      <Toolbar
        v-bind="{
          breadcrumbs,
          title
        }"
      />

      <!---------------stats cards-------------->
      <ClientDetailsTiles
        v-if="$hasPermission('statistics', 'statistics', 'list')"
        :requests="true"
      />

      <RequestState
        v-if="$hasPermission('statistics', 'statistics', 'list')"
        statesType="requests"
        style="margin-right: -5px"
      />
      <!--------------Add request------------------>
      <!-- <AddNewItem
        class="mt-10"
        @addNewItem="addRequest"
        v-bind="{ requests: true }"
      /> -->

      <!------------------------------------------------------->
      <!--------------Sub header (View done count)------------->
      <!------------------------------------------------------->

      <!-- Request filter -->
      <RequestFilter
        style="margin-right: -5px"
        :requestTypes="requestTypes"
        :ptClientName="ptClientName"
        @handleFilters="handleFilters"
        @search="handleFilters"
      />

      <!--Loader-->
      <loader v-if="isPageLoading" v-bind="loaderProps" />

      <!--no requests-->
      <no-data v-else-if="!requests.length" v-bind="noDataProps" />

      <!---------------------------------->
      <!------------All requests---------->
      <!---------------------------------->

      <v-row class="mb-3" v-else>
        <dataTableRequest
          v-bind="dataTableRequestsProps"
          @updateRequest="updateRequest"
          @showRequestDetails="showRequestDetails"
          @getEmployeesDataBySearchKey="getEmployeesDataBySearchKey($event)"
          @getEmployeesNextData="getEmployeesNextData"
          @getClientDataBySearchKey="getClientDataBySearchKey($event)"
          @getClientNextData="getClientNextData"
          @getPropertyDataBySearchKey="getPropertyDataBySearchKey($event)"
          @getPropertyNextData="getPropertyNextData"
          @resetAllProperties="resetAllProperties"
          @resetRealestatePagination="resetRealestatePagination"
        />
      </v-row>

      <!------------------------------------------>
      <!--------Pagination for all requests---------->
      <!------------------------------------------>

      <div class="text-center" v-if="pagination.pagesCount > 1">
        <Pagination :pagination="pagination" @onChange="loadRequests" />
      </div>

      <!------------------------------------>
      <!------------request details------------>
      <!------------------------------------>

      <v-layout v-show="sideDetailes">
        <sideRequestsDetailes
          v-bind="{
            requestInfo,
            requestIndex: activeRequestIndex,
            isExpenseRequestRefreshed,
            allEmployees,
            requestTypes
          }"
          @hide-task="statusSideDetailes(false)"
          @showRequest="statusSideDetailes(true)"
          @updateRequest="updateRequest"
          @closeDetails="closeSideDetails"
          @openAddBondModal="openAddBondModal"
          @refreshExpenseRequests="refreshExpenseRequests"
          @getEmployeesDataBySearchKey="getEmployeesDataBySearchKey($event)"
          @getEmployeesNextData="getEmployeesNextData"
        />
      </v-layout>

      <div class="d-flex align-center justify-end ml-md-8 fast-actions">
        <v-btn
          @click="openAddRequestDialog"
          data-test="open-add-modal"
          class="font-weight-bold fast-actions-btn pa-5 font-12"
          color="primary"
          large
        >
          <img
            class="mx-2"
            src="@/assets/img/svgs/001-writing.svg"
            loading="lazy"
          />
          <span class="mb-1">إضافة طلب</span>
        </v-btn>

        <!----------------------------->
        <!-------Fast actions---------->
        <!----------------------------->
        <fastActions :class="{ 'mb-5': !hasAddPermission }" />
      </div>
    </v-container>
    <!-- End container -->

    <AddBondModal
      v-if="addBondDialogState"
      v-bind="addBondModalProps"
      @closed-bond-dialog="setAddBondDialog(false)"
      @refreshBonds="refreshExpenseRequests(true)"
    />
    <AddRequestModal
      v-if="addRequestDialogState"
      :dialogState="addRequestDialogState"
      :addLoadingStatus="addLoadingStatus"
      :allProperties="allProperties"
      :allClients="allClients"
      :allPTClients="allPTClients"
      :allEmployees="allEmployees"
      :realEstateLoading="realEstateLoading"
      :requestTypes="requestTypes"
      :routerReqType="routerReqType"
      @resetAllProperties="resetAllProperties"
      @toggleDialogState="addRequestDialogState = $event"
      @addNewRequest="addRequest"
      @loadClientProperties="loadClientProperties"
      @getClientNextData="getClientNextData"
      @getClientDataBySearchKey="getClientDataBySearchKey"
      @getPropertyNextData="getPropertyNextData"
      @getPropertyDataBySearchKey="getPropertyDataBySearchKey"
      @setAddLoadingStatus="addLoadingStatus = $event"
    />
  </main>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import ClientDetailsTiles from '@/components/details/ClientDetailsTiles'
import Requests from '@/constans/Requests'
import dataTableRequest from '@/components/helper/dataTableItem'
import fastActions from '@/components/helper/fastActions'
import loader from '@/components/helper/loader.vue'
import Toolbar from '@/components/helper/Toolbar'
import Pagination from '@/components/helper/pagination.vue'
import noData from '@/components/helper/noData.vue'
import sideRequestsDetailes from '@/components/requests/sideRequestsDetailes.vue'
import {
  EnvService,
  requestsService,
  realEstateService,
  userService
} from '@/services'
import '@/styles/material-dashboard/_team.scss'
import '@/styles/material-dashboard/_properties.scss'
import RequestState from '@/components/properties/RealEstatStat'
import RequestFilter from '@/components/requests/RequestFilter.vue'
import {
  buildQueryString,
  excludeEmpty,
  getLocalNames,
  hasValue
} from '@/utils/general'

export default {
  name: 'Requests',
  components: {
    dataTableRequest,
    Pagination,
    fastActions,
    loader,
    Toolbar,
    noData,
    RequestState,
    ClientDetailsTiles,
    sideRequestsDetailes,
    RequestFilter,
    AddBondModal: () => import('@/components/modals/AddBondModal.vue'),
    AddRequestModal: () => import('@/components/modals/AddRequestModal.vue')
  },
  data() {
    return {
      ptClientName: '',
      requestTypes: [],
      cardsInfoState: true,
      ...Requests,
      isPageLoading: false,
      sideDetailes: false,
      requests: [],
      request: {},
      requestInfo: {},
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationEmployees: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      activeRequestIndex: 0,
      addBondDialogState: false,
      routerReqType: '',
      addRequestDialogState: false,
      expenseRequestId: '',
      isExpenseRequestRefreshed: false,
      employees: [],
      filterQuery: {},
      allEmployees: [],
      employeeMemberSearchKey: '',
      clients: [],
      allClients: [],
      allPTClients: [],
      realEstateLoading: false,
      clientSearchKey: '',
      paginationClient: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationProperty: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      allProperties: [],
      properties: [],
      propertySearchKey: '',
      addLoadingStatus: false
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'currentEnv']),
    ...mapGetters('accountState', ['isCustomer']),
    headers() {
      return !this.isCustomer
        ? this.headersArr
        : this.headersArr.filter((head) => {
            return head.isCustomer
          })
    },
    breadcrumbs() {
      return [
        {
          text: this.isCustomer ? 'الطلبات' : 'طلبات العملاء',
          disabled: true
        }
      ]
    },
    title() {
      return this.isCustomer ? 'الطلبات' : 'طلبات العملاء'
    },
    requestsLength() {
      return this.requests.length
    },
    loaderProps() {
      return {
        numberOfLines: 5,
        laoderClasses: 'property-col mb-2 shadow-border-radius px-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    },
    hasAddPermission() {
      return this.$hasPermission('properties', 'requests', 'add')
    },
    noDataConfig() {
      const data = {
        text: 'لا توجد طلبات مضافه حتي الأن',
        subTitle:
          'تظهر الطلبات التي يتم إضافتها في هذه القائمة مع إمكانية عرض الطلب والحالة الخاصة به',
        height: '50vh'
      }
      return data
    },
    noDataProps() {
      return {
        noDataText: this.noDataConfig.text,
        noDataSubTitle: this.noDataConfig.subTitle,
        height: this.noDataConfig.height,
        noDataIcon: require('@/assets/img/dashboard-icons/contract-icon.png')
      }
    },
    dataTableRequestsProps() {
      return {
        items: this.requests,
        headers: this.headers,
        allEmployees: this.allEmployees,
        allProperties: this.allProperties,
        allClients: this.allClients,
        complaints: true,
        updateTaskProp: this.request,
        requests: true,
        requestTypes: this.requestTypes,
        realEstateLoading: this.realEstateLoading
      }
    },
    currentUser() {
      return {
        _id: this.user._id,
        name: this.user.name,
        phoneNumber: this.user.phoneNumber,
        type: this.user.type,
        email: this.user.email
      }
    },
    addBondModalProps() {
      return {
        dialogState: this.addBondDialogState,
        defaultValues: {
          expenseRequestId: this.expenseRequestId,
          client: this.requestInfo.client,
          realEstateId: this.requestInfo.realEstate?._id,
          setClickedTag: this.getClientRole()
        }
      }
    }
    // isRequestTypesLoaded() {
    //   return !!JSON.parse(localStorage.getItem('requestTypes'))
    // }
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    // async setRequestsConfig() {
    //   if (!this.isRequestTypesLoaded) {
    //     try {
    //       const { data } = await requestsService.getRequestsConfig()
    //       this.requestTypes = data.configs
    //       localStorage.setItem(
    //         'requestTypes',
    //         JSON.stringify(this.requestTypes)
    //       )
    //     } catch {
    //       this.addNotification({
    //         text: 'لم نتمكن من تحميل أنواع الطلبات حاول في وقت لاحق',
    //         color: 'error'
    //       })
    //     }
    //   } else {
    //     this.requestTypes = JSON.parse(localStorage.getItem('requestTypes'))
    //   }
    // },
    handleFilters(filter) {
      // console.log(filter)
      this.pagination.pageNumber = 1
      this.filterQuery = excludeEmpty({ ...this.filterQuery, ...filter })
      this.loadRequests()
    },
    getClientRole() {
      return this.requestInfo.client?._id ===
        this.requestInfo?.realEstate?.owner
        ? 'owner'
        : 'renter'
    },
    resetAllProperties() {
      this.allProperties = []
    },
    resetRealestatePagination() {
      this.paginationProperty.pageNumber = 1
    },
    loadClientProperties(userId) {
      this.resetAllProperties()
      this.loadProperties(userId)
    },
    openAddRequestDialog() {
      if (!this.hasAddPermission) {
        this.addNotification({
          text: 'ليس لديك صلاحية لإضافة طلبات',
          color: 'error'
        })
        return
      }
      this.addRequestDialogState = true
    },
    pushNotification(text, color, timeout = 4000) {
      this.addNotification({
        text,
        color,
        timeout
      })
    },
    recalculatePagination(res) {
      this.pagination.pagesCount = Math.ceil(
        res.totalCount / this.pagination.pageSize
      )
      this.pagination.count = res.totalCount
    },
    async loadClients(query) {
      let data
      if (this.clientSearchKey) {
        data = `search=${this.clientSearchKey}`
      } else {
        data = `pageSize=${this.paginationClient.pageSize}&pageNumber=${this.paginationClient.pageNumber}`
      }
      try {
        const res = await EnvService.getAllClients(
          this.currentEnv._id,
          query,
          data
        )
        this.paginationClient.pagesCount = Math.ceil(
          res?.data?.count / this.paginationClient.pageSize
        )
        this.paginationClient.count = res?.data?.count ?? 0
        // this.recalculatePagination(res.data)
        this.clients = res?.data?.users ?? []
        this.allClients = [...this.allClients, ...this.clients]
      } catch {
        this.pushNotification('خطأ في تحميل العملاء', 'error')
      }
    },
    async loadPTClients() {
      this.isPageLoading = true
      const query = buildQueryString(
        {
          pageNumber: this.paginationClient.pageNumber,
          pageSize: this.paginationClient.pageSize,
          environmentId: this.currentEnv._id
        },
        ''
      )
      try {
        const { data } = await userService.getPotentialClients(query)
        this.allPTClients = data.users
        // this.recalculatePagination(data)
      } catch {
        this.addNotification({
          text: 'لم نتمكن من تحميل العملاء المحتملين',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    loadProperties(userId = '') {
      this.realEstateLoading = true
      let data = ''
      if (this.propertySearchKey) {
        data = `&search=${this.propertySearchKey}`
      } else {
        data = `pageSize=${this.paginationProperty.pageSize}&pageNumber=${this.paginationProperty.pageNumber}`
      }
      const body = {
        account: this.currentEnv?.owner.id,
        user: userId
      }
      realEstateService
        .getRealEstates(body, data)
        .then((res) => {
          this.paginationProperty.pagesCount = Math.ceil(
            res?.data?.count / this.paginationProperty.pageSize
          )
          this.paginationProperty.count = res?.data?.count ?? 0
          this.properties = res?.data?.realEstates ?? []
          this.allProperties = [...this.allProperties, ...this.properties]
        })
        .catch(() => {
          this.pushNotification('خطأ في تحميل العقارات', 'error')
        })
        .finally(() => {
          this.realEstateLoading = false
        })
    },
    getEmployeesDataBySearchKey(e) {
      this.employeeMemberSearchKey = e
      if (this.employeeMemberSearchKey) {
        this.loadEmployees()
      }
    },
    getEmployeesNextData() {
      if (
        this.paginationEmployees.pageNumber <
        this.paginationEmployees.pagesCount
      ) {
        this.paginationEmployees.pageNumber += 1
        this.loadEmployees()
      }
    },
    getClientDataBySearchKey(e) {
      this.clientSearchKey = e
      if (this.clientSearchKey) {
        this.loadClients()
      }
    },
    getPropertyDataBySearchKey({ e, itemData }) {
      if (itemData?.client?._id) {
        this.propertySearchKey = e
        this.loadProperties(itemData.client._id)
      }
    },
    getClientNextData() {
      if (this.paginationClient.pageNumber < this.paginationClient.pagesCount) {
        this.paginationClient.pageNumber += 1
        this.loadClients()
      }
    },
    getPropertyNextData(itemData) {
      if (
        this.paginationProperty.pageNumber <
          this.paginationProperty.pagesCount &&
        itemData.client?._id
      ) {
        this.paginationProperty.pageNumber += 1
        this.loadProperties(itemData.client._id)
      }
    },
    async addRequest(data) {
      this.addLoadingStatus = true
      try {
        await requestsService.addRequest(data)
        this.addNotification({
          text: 'تم إضافة الطلب بنجاح',
          color: 'success'
        })
        this.pagination.pageNumber = 1
        this.loadRequests()
      } catch {
        this.addNotification({
          text: 'خطأ في إضافة الطلب، برجاء المحاوله مجددا',
          color: 'error'
        })
      } finally {
        this.addRequestDialogState = false
        this.addLoadingStatus = false
      }
    },
    async setRequestTypes() {
      try {
        const {
          data: { requestTypes }
        } = await requestsService.getRequestsTypes()
        this.requestTypes = requestTypes
      } catch {
        this.addNotification({
          text: 'حدث خطأ ما برجاء إعاده المحاوله',
          color: 'error'
        })
      }
    },
    async loadRequests() {
      try {
        this.isPageLoading = true

        const query = buildQueryString({
          pageSize: this.pagination.pageSize,
          pageNumber: this.pagination.pageNumber,
          environmentId: this.currentEnv._id,
          ...(this.isCustomer && { applicant: this.user?._id }),
          createdAt: -1,
          ...this.filterQuery
        })
        const { data } = await requestsService.getAllRequests(query)
        this.requests = data.items
        this.recalculatePagination(data)
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل طلبات الصيانه، يرجى المحاولة مجددا',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    async updateRequest(payload) {
      try {
        const requestId = payload.fromDetails
          ? payload.updatedData._id
          : payload.requestId
        const { data } = await requestsService.updateRequest(
          requestId || payload._id,
          payload.updatedData
        )
        if (payload.fromDetails) {
          this.requests.splice(payload.index, 1, data)
        } else {
          Object.keys(payload.updatedData).forEach((key) => {
            this.$set(this.requests[payload.index], key, {
              nameAr: getLocalNames(payload.updatedData[key]),
              nameEn: payload.updatedData[key]
            })
            // To save the new client's clientRole (relationship between client and realestate)
            if (key === 'realEstate') {
              this.$set(this.requests[payload.index], 'client', data.client)
            }
          })

          if (this.requests[payload.index].expenseLimit !== data.expenseLimit) {
            this.$set(
              this.requests[payload.index],
              'expenseLimit',
              data.expenseLimit
            )
          }
        }
        this.addNotification({
          text: 'تم تحديث بيانات الطلب',
          color: 'success'
        })
      } catch {
        if (!payload.fromDetails) {
          const key = Object.keys(payload.updatedData)[0]
          this.$set(this.requests[payload.index], key, null)
        }
        this.addNotification({
          text: 'لم يتم تحديث بيانات الطلب، يرجى المحاوله مجددا',
          color: 'error'
        })
      }
    },
    // Get data when user clicked on row and open side details and view this data
    showRequestDetails(payload) {
      this.requestInfo = payload.requestInfo
      this.activeRequestIndex = payload.index
      this.statusSideDetailes(true)
      this.$router.replace({
        path: '/dashboard/requests',
        query: { request: this.requestInfo.number }
      })
    },
    // Change status side details open and close
    statusSideDetailes(status) {
      this.sideDetailes = status
    },
    closeSideDetails(payload) {
      this.statusSideDetailes(false)
      // if (payload.isUpdated) {
      //   delete payload.updatedData.client // temp solution till fixing the backEnd issue or finding a way to trigger only the changes from the front side
      //   this.updateRequest(payload)
      // }
      // this.$router.replace({
      //   path: '/dashboard/requests'
      // })
      this.loadRequests()
    },
    setAddBondDialog(val) {
      this.addBondDialogState = val
      this.sideDetailes = !val
    },
    openAddBondModal(expenseRequestId) {
      this.expenseRequestId = expenseRequestId
      this.setAddBondDialog(true)
    },
    refreshExpenseRequests(val) {
      this.isExpenseRequestRefreshed = val
    },
    loadEmployees() {
      let params = `&pageSize=${this.paginationEmployees.pageSize}&pageNumber=${this.paginationEmployees.pageNumber}&registered=1`
      if (this.employeeMemberSearchKey) {
        params += `&search=${this.employeeMemberSearchKey}`
      }

      EnvService.getAllEmployee(this.currentEnv._id, params)
        .then((res) => {
          this.paginationEmployees.pagesCount = Math.ceil(
            res?.data?.count / this.paginationEmployees.pageSize
          )
          this.paginationEmployees.count = res?.data?.count ?? 0
          this.employees = res?.data?.users ?? []
          this.allEmployees = [...this.allEmployees, ...this.employees]
        })
        .catch(() => {
          this.addNotification({
            text: 'خطأ في تحميل الموظفين',
            color: 'error',
            duration: 4000
          })
        })
    },
    resetQuery() {
      const isThereQuery = Object.keys(this.$route.query).length
      if (isThereQuery) {
        this.$router.replace({ query: null })
      }
    },
    handleSearchPTClinet() {
      this.ptClientName = this.$route.query?.client ?? ''
      this.resetQuery()
    },
    handleAddBondsReq() {
      // check if the query is not empty open addBondDialogState
      if (hasValue(this.$route.query.reuquestType)) {
        this.addRequestDialogState = true
        this.routerReqType = this.$route.query?.reuquestType
      }
    }
  },
  metaInfo() {
    return {
      title: 'الطلبات '
    }
  },
  created() {
    this.$emit('updateInformativeTip', this.$route.meta?.informativeTip)
    this.setRequestTypes()
    this.loadRequests()
    this.loadEmployees()
    this.loadClients()
    this.loadPTClients()
    if (this.isCustomer) {
      this.loadProperties(this.user?._id)
    }
    this.handleSearchPTClinet()
    this.handleAddBondsReq()
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/fastactions.scss';
// @import '@/styles/material-dashboard/_statistics-cards.scss';
.dataTaskTable.hectar-table.noHeader
  .v-data-table__wrapper
  table
  .v-data-table-header {
  opacity: 1;
  display: table-header-group;
}
</style>
